import Config from "@/vendor/config"
import Request from "@/api/request.js"

// 直播分类
const liveTypes = (params) => {
	return Request.setBaseUrl(Config.apiUrl).request(Request.post, 'api/live/types', params)
}

// 直播列表
const liveLists = (params) => {
	return Request.setBaseUrl(Config.apiUrl).request(Request.post, 'api/live/lists', params)
}

// 直播详情
const liveDetail = (params) => {
	return Request.setBaseUrl(Config.apiUrl).request(Request.post, 'api/live/detail', params)
}

// 直播日历
const liveCalendar = (params) => {
	return Request.setBaseUrl(Config.apiUrl).request(Request.post, 'api/live/calendar', params)
}

// 按日期查询直播列表
const liveDayLives = (params) => {
	return Request.setBaseUrl(Config.apiUrl).request(Request.post, 'api/live/dayLives', params)
}

// 设置提醒
const liveNotice= (params) => {
	return Request.setBaseUrl(Config.apiUrl).request(Request.post, 'api/live/notice', params)
}

export default {
	liveTypes,
	liveLists,
	liveDetail,
	liveCalendar,
	liveDayLives,
	liveNotice
}
