<template>
	<van-overlay :show="isLoading" @click="onTouch">
		<div class="share-mask">
			<img src="@/assets/img/share.png" alt="">
		</div>
	</van-overlay>
</template>

<script>
	export default {
		props: {
			loading: {
				default: false
			}
		},
		data() {
			return {
				isLoading: ""
			};
		},
		created() {
			this.isLoading = this.loading;
		},
		watch: {
			loading(newVal) {
				this.isLoading = newVal;
			}
		},
		methods: {
			onTouch() {
				this.$emit("Touch")
				// this.isLoading = false
			},
		}
	};
</script>

<style>

</style>