<template>
	<div class="Telecast">
		<div class="header">
			<div class="header-image">
				<img src="@/assets/img/icon80.png" alt="">
			</div>
			<div class="header-top">
				<div class="header-top-left" @click="goBack()">
					<img src="@/assets/img/icon81.png" alt="">
				</div>
				<div class="header-top-right" @click="isShowShare = true">
					<img src="@/assets/img/icon82.png" alt="">
				</div>
			</div>
		</div>
		<div class="container">
			<div class="container-top">
				<div :class="titleActive == item.id ? 'container-title-active': 'container-title'"
					v-for="item in titleList" @click="onTitleList(item.id)">
					{{item.title}}
				</div>
			</div>
			<div class="container-body">
				<div class="body-top">
					<div :class="navActive == vo.type_id ? 'body-title-active': 'body-title'" v-for="vo in navList"
						@click="onNavList(vo.type_id)">
						{{vo.name}}
					</div>
				</div>
				<VantList class="list-view" :loading="loading" :finished="finished" :is-empty="isEmpty"
					@onLoad="onLoad">
					<div class="body-list">
						<div :class=" ['list-item', { 'list-item-active': item.state == 2 }]"
							v-for="(item,index) in liveList"
							@click="$router.push({ name: 'TelecastDetail', params:{id: item.live_id}})">
							<div class="item-picture">
								<img :src="item.thumb" alt="">
								<div class="item-picture-box">{{item.type_name}}</div>
							</div>
							<div class="item-right">
								<div class="item-right-title">
									{{item.title}}
								</div>
								<div class="item-right-box">
									<div class="item-right-box-icon" v-if="item.state == 1 || item.state == 3">
										<img src="@/assets/img/icon83.png" alt="">时间：
									</div>
									<div class="item-right-box-icon" v-else>
										<img src="@/assets/img/icon84.png" alt="">
									</div>
									<div class="item-right-box-text" v-if="item.state == 1 || item.state == 3">
										{{item.play_date}}
									</div>
									<div class="item-right-box-text item-right-box-text-active" v-if="item.state == 2">
										正在直播
									</div>
								</div>
								<div class="item-right-botton" v-if="item.state == 1 && item.is_notice == 2"
									@click.stop="onLiveNotice(item.live_id,index)">开播提醒</div>
								<div class="item-right-botton" v-if="item.state == 2">立即观看</div>
								<div class="item-right-botton" v-if="item.state == 3">直播回放</div>
								<div class="botton-active" v-if="item.state == 1 && item.is_notice == 1">已设提醒</div>
							</div>
							<div class="list-item-watermark" v-if="item.state == 2">
								<img src="@/assets/img/icon92.png" alt="">
							</div>
						</div>
					</div>
				</VantList>
			</div>
		</div>
		<div class="footer" @click="$router.push({ name: 'Calendar'})">
			<div class="footer-botton">
				直播日历
			</div>
		</div>
		<Share :loading="isShowShare" @Touch="share()"></Share>
	</div>
</template>

<script>
	import Live from '@/api/live';
	import Vant from '@/vendor/vant';
	import VantList from '@/components/VantList';
	import Config from '@/vendor/config';
	import Share from '@/components/Share';
	export default {
		name: 'Telecast',
		data() {
			return {
				titleList: [{
					id: 1,
					title: '在线直播'
				}, {
					id: 2,
					title: '直播回放'
				}],
				navList: [{
					type_id: 0,
					name: '全部'
				}],
				titleActive: 1,
				navActive: 0,
				page: 1,
				limit: 10,
				loading: true,
				finished: false,
				isEmpty: false,
				liveList: [],
				isShowShare: false,
			}
		},
		created() {
			this.$store.commit('keep/setKeep', 'Telecast');
			this.getType()
			this.onLoad()

			wx.onMenuShareTimeline({
				title: document.title,
				link: window.Location.href,
				imgUrl: Config.shareLogoUrl,
				success: () => {
					this.isShowShare = false;
					Vant.Toast.success('分享成功');
				},
				error: () => {
					console.log(12);
				}
			});

			wx.onMenuShareAppMessage({
				title: document.title,
				link: window.Location.href,
				imgUrl: Config.shareLogoUrl,
				success: () => {
					this.isShowShare = false;
					Vant.Toast.success('分享成功');
				},
				error: () => {
					console.log(12);
				}
			});

		},
		methods: {
			share() {
				this.isShowShare = false;
			},
			// 获取分类
			getType() {
				Live.liveTypes()
					.then(result => {
						result.data.forEach(item => {
							this.navList.push(item)
						})
					}, error => {
						Vant.Toast(error.msg);
					})
			},
			// 获取列表
			onLoad() {
				this.loading = true;
				let params = {
					order: this.titleActive,
					type_id: this.navActive,
					page: this.page,
					limit: this.limit
				}
				Live.liveLists(params)
					.then(result => {
						this.page++;
						this.liveList = this.liveList.concat(result.data.list);
						this.loading = false;

						if (this.liveList.length >= result.data.total) {
							this.finished = true;
						}
						if (this.liveList.length <= 0) {
							this.isEmpty = true;
						}
					}, error => {
						Vant.Toast(error.msg);
						console.log(1)
					})
			},
			// 设置提醒
			onLiveNotice(id, index) {
				Live.liveNotice({
						live_id: id
					})
					.then(result => {
						Vant.Dialog.alert({
							title: '订阅通知',
							message: '订阅成功',
							theme: 'round-button',
						}).then(() => {
							this.liveList[index].is_notice = 1
						});
					}, error => {
						Vant.Toast(error.msg);
					})
			},
			onTitleList(id) {
				this.titleActive = id
				this.page = 1,
					this.finished = false;
				this.isEmpty = false;
				this.liveList = []
				this.onLoad()
			},
			onNavList(id) {
				this.navActive = id
				this.page = 1,
					this.finished = false;
				this.isEmpty = false;
				this.liveList = []
				this.onLoad()
			},
			goBack() {
				this.$router.go(-1)
			},
		},
		components: {
			VantList,
			Share
		}
	}
</script>

<style scoped lang="less">
	.Telecast {
		padding-bottom: 70px;
		box-sizing: border-box;

		.header {
			width: 100%;
			height: 202px;
			position: relative;

			.header-image {
				width: 100%;
				height: 100%;

				img {
					width: 100%;
					height: 100%;
					object-fit: cover;
				}
			}

			.header-top {
				.header-top-left {
					width: 28px;
					height: 28px;
					position: absolute;
					top: 8px;
					left: 8px;

					img {
						width: 100%;
						height: 100%;
						object-fit: cover;
					}
				}

				.header-top-right {
					width: 28px;
					height: 28px;
					position: absolute;
					top: 8px;
					right: 8px;

					img {
						width: 100%;
						height: 100%;
						object-fit: cover;
					}
				}
			}
		}

		.container {
			.container-top {
				padding: 8px 8px 0;
				background: #FFF;
				box-sizing: border-box;
				display: flex;
				align-items: center;

				.container-title {
					font-size: 15px;
					line-height: 22px;
					color: #44484E;
					margin-right: 30px;
					padding-bottom: 6px;
					border-bottom: 2px solid #fff;
					box-sizing: border-box;
				}

				.container-title-active {
					font-weight: bold;
					font-size: 15px;
					line-height: 22px;
					color: #3377FF;
					margin-right: 30px;
					padding-bottom: 6px;
					border-bottom: 2px solid #3377FF;
					box-sizing: border-box;
				}
			}

			.container-body {
				padding: 0 8px;

				.body-top {
					display: flex;
					align-items: center;
					margin: 8px 0;

					.body-title {
						font-size: 13px;
						line-height: 19px;
						color: #777B81;
						background: #FFF;
						border-radius: 100px;
						padding: 2px 10px;
						margin-right: 12px;
						box-sizing: border-box;
					}

					.body-title-active {
						font-size: 13px;
						line-height: 19px;
						color: #FFF;
						background: #3377FF;
						border-radius: 100px;
						padding: 2px 10px;
						margin-right: 12px;
						box-sizing: border-box;
					}
				}

				.body-list {
					.list-item-active {
						background: linear-gradient(180deg, #EFF5FF 0%, #E0F0FF 100%) !important;
						border-radius: 8px;
					}

					.list-item:first-child {
						margin-top: 0;
					}

					.list-item {
						display: flex;
						align-items: center;
						background: #FFF;
						border-radius: 8px;
						padding: 8px;
						box-sizing: border-box;
						position: relative;
						margin-top: 8px;

						.list-item-watermark {
							width: 123px;
							height: 84px;
							position: absolute;
							right: 0;
							bottom: 0;
							z-index: 0;
							img {
								width: 100%;
								height: 100%;
								object-fit: cover;
							}
						}

						.item-picture {
							width: 130px;
							height: 130px;
							min-width: 130px;
							position: relative;
							border-radius: 2px;
							overflow: hidden;
							img {
								width: 100%;
								height: 100%;
								border-radius: 2px;
								object-fit: cover;
							}

							.item-picture-box {
								width: 75px;
								position: absolute;
								top: 0;
								left: 0;
								background: url('~@/assets/img/icon91.png');
								background-size: cover;
								font-size: 13px;
								line-height: 19px;
								color: #FFF;
								padding-left: 3px;
								padding-top: 2px;
								box-sizing: border-box;
							}
						}

						.item-right {
							padding-left: 7px;

							.item-right-title {
								font-weight: bold;
								font-size: 17px;
								line-height: 20px;
								color: #44484E;
								min-height: 40px;
								display: -webkit-box;
								-webkit-box-orient: vertical;
								-webkit-line-clamp: 2;
								overflow: hidden;
							}

							.item-right-box {
								display: flex;
								margin-top: 5px;

								.item-right-box-icon {
									font-size: 13px;
									line-height: 19px;
									color: #777B81;
									display: flex;
										
									img {
										width: 16px;
										height: 16px;
										margin-right: 2px;
										object-fit: cover;
									}
								}

								.item-right-box-text {
									width: 110px;
									font-size: 13px;
									line-height: 19px;
									color: #777B81;
								}

								.item-right-box-text-active {
									color: #FF4E3B;
								}
							}

							.item-right-botton {
								min-width: 110px;
								max-width: 110px;
								font-weight: bold;
								font-size: 15px;
								line-height: 22px;
								color: #FFF;
								text-align: center;
								padding: 3px 21px;
								background: #128AF6;
								box-shadow: 0px 2px 3px rgba(116, 111, 243, 0.35);
								border-radius: 100px;
								margin-top: 19px;
								box-sizing: border-box;
								position: relative;
								z-index: 1;
							}

							.botton-active {
								border: 1px solid #128AF6;
								min-width: 108px;
								max-width: 108px;
								text-align: center;
								font-weight: bold;
								font-size: 15px;
								line-height: 22px;
								color: #128AF6;
								padding: 3px 21px;
								box-shadow: 0px 2px 3px rgba(116, 111, 243, 0.35);
								border-radius: 100px;
								margin-top: 19px;
								box-sizing: border-box;
								position: relative;
								z-index: 1;
							}
						}
					}
				}
			}
		}

		.footer {
			position: fixed;
			width: 100%;
			bottom: 8px;
			left: 0;
			padding: 8px;
			box-sizing: border-box;

			.footer-botton {
				background: linear-gradient(92.48deg, #128AF6 0%, #3377FF 100%);
				box-shadow: 0px 2px 3px rgba(116, 111, 243, 0.35);
				border-radius: 100px;
				font-weight: bold;
				font-size: 15px;
				line-height: 22px;
				text-align: center;
				color: #FFF;
				padding: 11px 0;
			}
		}
	}
</style>
